<template>
  <div class="send-patient-email">
    <main class="send-patient-email__message-template patient-email-message-template">
      <div
        v-if="shouldShowUnsubscribeMessage && !isPatientSubscribed"
        class="send-patient-email__unsubscribe-message flex--space-between--center"
      >
        <div>
          <span class="unsubscribe-message__icon fas fa-exclamation-triangle mr7"></span>
          {{ $t('dashboard.label.patientUnsubscribedPatientCard') }}
        </div>
        <button class="unsubscribe-message__resubscribe-button" @click="resubscribePatient">
          {{ $t('dashboard.label.resubscribe') }}
        </button>
      </div>

      <dashboard-constraint
        v-if="hasPatientConsent"
        id="patientEmailConstraint"
        name="patientEmailConstraint"
        :value="patientEmailConsent"
        class="patient-accept-receiving-constraint"
        required
        @input="updatePatientEmailConsent"
      >
        <template slot="constraint">
          <span
            class="patient-accept-receiving-constraint__constraint-label"
            :class="{ error: hasEmailConstraintError }"
          >
            {{ $t('dashboard.consent.sendInvite.agreeToMarketingOffersByEmail') }}
          </span>
          <span
            v-tooltip="{
              placement: 'right-start',
              content: $t('dashboard.consent.sendInviteHint.agreeToMarketingOffersByEmail'),
              trigger: tooltip
            }"
            class="custom-icon icon-info mt2"
          ></span>
        </template>
      </dashboard-constraint>

      <message-template
        type="EMAIL"
        :initial-language="patient.locale"
        :is-footer-visible="isFooterVisible"
        :has-scroll="false"
        should-use-external-language
        :external-language="language"
        :template-errors="templateErrors"
        @update-template-text="updateTemplateText"
        @select-language="updateLanguage"
      >
        <div slot="send-button" slot-scope="{ sendTemplate }">
          <button class="rounded-button-orange" @click="sendTemplate">
            {{ $t('dashboard.action.sendEmail') }}
          </button>
        </div>
      </message-template>
    </main>

    <invite-template-sent-modal
      :should-redirect-to-dashboard="shouldRedirectToDashboard"
      @close-modal="onInviteTemplateSentModalClose"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get, pick } from 'lodash';

import MessageTemplate from '@/modules/dashboard/components/dashboard/common/MessageTemplate';
import InviteTemplateSentModal from '@/modules/dashboard/components/dashboard/invite-patient/InviteTemplateSentModal';
import DashboardConstraint from '@/modules/dashboard/components/dashboard/common/DashboardConstraint';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import { DEFAULT_LOCALE } from '@/constants';
import { UPDATE_PATIENT_FIELDS } from '@/modules/dashboard/api/constants';

export default {
  name: 'SendPatientInviteWithEmail',
  components: {
    DashboardConstraint,
    InviteTemplateSentModal,
    MessageTemplate
  },
  inject: ['$validator'],
  props: {
    patientEmailConsent: {
      type: Boolean,
      default: false
    },
    isFooterVisible: {
      type: Boolean,
      default: false
    },
    hasPatientConsent: {
      type: Boolean,
      default: false
    },
    shouldRedirectToDashboard: {
      type: Boolean,
      default: true
    },
    language: {
      type: String,
      default: DEFAULT_LOCALE
    },
    shouldShowUnsubscribeMessage: {
      type: Boolean,
      default: false
    },
    templateErrors: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      patient: dashTypes.getters.CURRENT_PATIENT
    }),
    isPatientSubscribed() {
      return get(this.patient, 'marketingEmailAccepted', false);
    },
    hasEmailConstraintError() {
      return this.errors.has('patientEmailConstraint');
    },
    tooltipTrigger() {
      return isMobileDevice() ? 'click' : 'hover';
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      updatePatientInfo: dashTypes.actions.UPDATE_PATIENT_INFO
    }),
    async updateTemplateText(messageTemplate) {
      this.$emit('update-template-text-email', messageTemplate);
    },
    async resubscribePatient() {
      const patientData = {
        ...pick(this.patient, UPDATE_PATIENT_FIELDS),
        marketingEmailAccepted: true
      };

      this.setLoading(true);
      await this.updatePatientInfo({ patientId: this.patient.id, patientData });
      this.setLoading(false);
    },
    onInviteTemplateSentModalClose() {
      this.$emit('invite-template-sent-modal-close');
    },
    updatePatientEmailConsent(emailConsent) {
      this.$emit('update-email-consent', emailConsent);
    },
    updateLanguage(language) {
      this.$emit('update-language', language);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/invite-patients/patient-card-invite-shared';
@import '../../../../../assets/css/common/icons.css';

.send-patient-email {
  font-size: 15px;
  line-height: 17px;
  color: $text-color;

  &__header {
    height: 50px;
    padding: 0 20px 0 25px;
  }

  &__email {
    margin: 30px 0 20px 0;

    &__title {
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 15px;
    }
  }

  &__unsubscribe-message {
    margin: 0 25px 20px;
    padding: 15px;
    background: $beige;
    border-radius: 8px;

    font-weight: 300;
    font-size: 13px;
    line-height: 15px;

    color: $text-color;
  }
}

.unsubscribe-message {
  &__icon {
    color: $pale-orange-color;
  }

  &__resubscribe-button {
    font-size: 13px;
    font-weight: 600;
    color: $orange-color;

    background: none;
    border: none;
    outline: none;
  }
}

.rounded-input {
  width: auto;
  min-height: 36px;
}

@media (max-width: 767px) {
  .send-patient-email {
    font-size: 13px;
    line-height: 15px;
    background-color: $white;

    &__unsubscribe-message {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
</style>
