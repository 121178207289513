<template>
  <modal
    name="invite-template-sent-modal"
    :classes="['modal-window']"
    transition="scale"
    :click-to-close="false"
    :width="300"
    height="auto"
    @before-open="onModalBeforeOpen"
    @before-close="onModalBeforeClose"
  >
    <div class="modal-container">
      <div class="modal-window-content flex-column--align-center">
        <p class="title mb30">{{ titleText }}</p>
        <div v-if="hasUnsubscribedEmails" class="unsubscribe-emails__container flex mb30">
          <span class="unsubscribe-emails__icon fas fa-exclamation-triangle mr10"></span>
          <span>
            <i18n path="dashboard.label.messageWasNotSent" tag="span">
              <span class="bold">{{ transformedUnsubscribeEmails }}</span>
            </i18n>
          </span>
        </div>
        <button class="rounded-button-white " @click="closeModal">
          {{ $t('dashboard.button.ok') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { isEmpty } from 'lodash';

import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'InviteTemplateSentModal',
  props: {
    shouldRedirectToDashboard: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isInvitationSent: true,
      unsubscribedEmails: []
    };
  },
  computed: {
    titleText() {
      return this.$t(
        `dashboard.label.${this.isInvitationSent ? 'invitationSent' : 'invitationNotSent'}`
      );
    },
    hasUnsubscribedEmails() {
      return !isEmpty(this.unsubscribedEmails);
    },
    transformedUnsubscribeEmails() {
      return this.unsubscribedEmails.join(', ');
    }
  },
  methods: {
    onModalBeforeOpen({ params }) {
      if (!params) {
        return;
      }

      const { isInvitationSent, unsubscribedEmails } = params;

      this.isInvitationSent = isInvitationSent;
      this.unsubscribedEmails = unsubscribedEmails;
    },
    onModalBeforeClose() {
      this.isInvitationSent = true;
      this.unsubscribedEmails = [];
    },
    closeModal() {
      this.$emit('close-modal');

      if (isMobileDevice() && this.shouldRedirectToDashboard) {
        this.$router.push({ name: 'Dashboard' });
      }

      this.$modal.hide('invite-template-sent-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/buttons';

.modal {
  &-window-content {
    padding: 30px 25px;
  }
}

.title {
  font-size: 13px;
  line-height: 19px;
  color: $text-color;
}

.unsubscribe-emails {
  &__container {
    padding: 15px;
    background: $beige;
    border-radius: 8px;

    font-weight: 300;
    font-size: 13px;
    line-height: 15px;

    color: $text-color;
  }

  &__icon {
    color: $pale-orange-color;
  }
}
</style>
