import { render, staticRenderFns } from "./SendPatientInviteWithEmail.vue?vue&type=template&id=76485509&scoped=true&"
import script from "./SendPatientInviteWithEmail.vue?vue&type=script&lang=js&"
export * from "./SendPatientInviteWithEmail.vue?vue&type=script&lang=js&"
import style0 from "./SendPatientInviteWithEmail.vue?vue&type=style&index=0&id=76485509&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76485509",
  null
  
)

export default component.exports