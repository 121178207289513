<template>
  <div class="dashboard-constraint flex" :class="{ 'dashboard-constraint--disabled': disabled }">
    <input
      :id="id"
      v-validate="{ required }"
      :name="name"
      :checked="value"
      type="checkbox"
      :disabled="disabled"
      @change="updateConstraint"
    />
    <label
      :for="id"
      class="dashboard-constraint__label checkbox--with-border m0"
      :class="{ error: hasError }"
    ></label>
    <slot name="constraint">
      <span class="dashboard-constraint__text constraint-text" :class="{ error: hasError }">
        {{ constraintText }}
      </span>
      <span
        v-if="hintText"
        v-tooltip="tooltipConfig"
        class="dashboard-constraint__tooltip custom-icon icon-info mt2"
      ></span>
    </slot>
  </div>
</template>

<script>
import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'DashboardConstraint',
  inject: ['$validator'],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      default: 'dashboardConstraint'
    },
    constraintText: {
      type: String,
      default: ''
    },
    hintText: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'dashboardConstraint'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasError() {
      return this.errors.has(this.name);
    },
    tooltipConfig() {
      return {
        content: this.hintText,
        placement: 'right-start',
        trigger: isMobileDevice() ? 'click' : 'hover'
      };
    }
  },
  methods: {
    updateConstraint({ target: { checked } }) {
      this.$emit('input', checked);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/checkbox';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/text';
@import '../../../../../assets/css/common/icons.css';
@import '../../../../../assets/css/common/errors';

.dashboard-constraint {
  &--disabled {
    opacity: 0.5;
  }

  &__label {
    height: 19px;
  }

  &__text {
    align-self: center;
  }
}
</style>
